<template>
  <b-overlay variant="transparent" :opacity="1" :show="isPageLoading" spinner-variant="primary">
    <b-row>
      <b-col sm="12">
        <form-wizard title="" subtitle="" shape="square" color="#7367F0" finish-button-text="Selesai" back-button-text="Kembali">
          <tab-content title="Informasi Paket Item" :before-change="submitStepOne">
            <validation-observer ref="stepOne" tag="form">
              <b-row>
                <b-col sm="12" md="8">
                  <form-select-nested ref="categorySelect" @value-changed="stepOneData.name_category = $event.label" url="v1/item_category_select2" :rules="{required: true}" v-model="stepOneData.id_category" label="Kategori"></form-select-nested>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="8">
                  <form-input v-model="stepOneData.code" label="Kode" :rules="{required: true}"></form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <form-input v-model="stepOneData.name" label="Nama" :rules="{required: true}"></form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <form-input v-model="stepOneData.alias" label="Alias" :rules="{required: true}"></form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="8">
                  <form-input v-model="stepOneData.barcode" label="Barcode" :rules="{required: true}"></form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="8">
                  <form-input-number v-model="stepOneData.min_stock" label="Min Stok" :rules="{required: true}"></form-input-number>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="8">
                  <form-checkbox v-model="stepOneData.is_active" label="Aktif"></form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <form-input v-model="stepOneData.note" label="Keterangan" :rules="{required: false}"></form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" class="mb-5 mt-2">
                  <h5>Komposisi Item</h5>
                  <b-row v-for="(tr,i) in stepOneData.details" :key="i">
                    <b-col sm="12" md="2">
                      <form-select :ref="`itemSelect-${i}`" size="sm" url="v1/item_select2" v-model="tr.id_item" @value-changed="tr.name_item = $event.label" :rules="{required: true}" :label="`Item ke ${i+1}`"></form-select>
                    </b-col>
                    <b-col sm="12" md="1">
                      <form-input size="sm" @input="modifyRowItem(i)" v-model="tr.qty" :rules="{required: true}" :label="`Qty`"></form-input>
                    </b-col>
                    <b-col sm="12" md="2">
                      <form-select-static @value-changed="unitChange($event,i)" @open-search="findUnits(i)" :options="unitOptions(i)" size="sm" v-model="tr.id_unit" :rules="{required: true}" :label="`Satuan`"></form-select-static>
                    </b-col>
                    <b-col sm="12" md="2">
                      <form-input @input="modifyRowItem(i)" size="sm" v-model="tr.price" :rules="{required: true}" :label="`Harga`"></form-input>
                    </b-col>
                    <b-col sm="12" md="2">
                      <form-input size="sm" disabled v-model="tr.price_total" :rules="{required: true}" :label="`Harga Total`"></form-input>
                    </b-col>
                    <b-col sm="12" md="2">
                      <form-input size="sm" v-model="tr.note" :rules="{required: false}" :label="`Keterangan`"></form-input>
                    </b-col>
                    <b-col sm="12" md="1" class="mt-1">
                      <button-action v-if="i>0" @clicked="deleteItem(i)" variant="danger" size="sm" feather-icon="TrashIcon"></button-action>
                      <button-action v-if="(i+1)==stepOneData.details.length" @clicked="addItem" size="sm" feather-icon="PlusIcon"></button-action>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mt-2" sm="12" offset-md="9" md="3">
                      <form-input v-model="stepOneData.price" :rules="{required: true}" disabled label="Harga Total"></form-input>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content title="Harga Jual" :before-change="submitStepTwo">
            <validation-observer ref="stepTwo" tag="form">
              <b-row>
                <b-col v-for="(tr,i) in salePricePaginateData.data" :key="i" sm="12" md="6">
                  <b-card :title="tr.label">
                    <b-row>
                      <b-col sm="12" v-for="v in tr.details" :key="v.uniqid">
                        <validation-provider :name="'Harga '+v.name_payment_partner" :rules="{required: true, numeric: true}" v-slot="validationContext">
                          <b-form-group :label="v.name_payment_partner" :label-for="v.uniqid">
                            <b-form-input :id="v.uniqid" :value="v.price" @input="$store.commit('utils/UPDATE_VALUE',{uniqid: v.uniqid, value: $event})" :aria-describedby="`${v.uniqid}-feedback`" :state="getValidationState(validationContext)"></b-form-input>
                            <b-form-invalid-feedback :id="`${v.uniqid}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col sm="12" class="text-center">
                  <b-pagination :value="salePricePage" :per-page="salePricePaginateData.perPage" :total-rows="salePricePaginateData.total" @input="$store.commit('utils/CHANGE_PAGE',$event)"></b-pagination>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import FormSelectNested from '@/my-components/FormSelectNested'
import FormCheckbox from '@/my-components/FormCheckbox'
import FormInput from '@/my-components/FormInput'
import FormInputNumber from '@/my-components/FormInputNumber'
import FormSelect from '@/my-components/FormSelect'
import FormSelectStatic from '@/my-components/FormSelectStatic'
import ButtonAction from '@/my-components/ButtonAction'
import { mapState, mapGetters } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {BCard,BFormGroup,BFormInput,BFormInvalidFeedback,BPagination,BOverlay} from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components:{
    FormWizard,
    TabContent,
    ValidationObserver,
    ValidationProvider,
    BCard,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BPagination,
    FormInput,
    FormSelect,
    FormSelectNested,
    FormCheckbox,
    ButtonAction,
    FormSelectStatic,
    BOverlay,
    FormInputNumber
  },
  data(){
    return {
      stepOneData:{
        id: this.$route.params.id,
        id_category: null,
        name_category: "",
        code: "",
        name: "",
        alias: "",
        barcode: "",
        min_stock: 0,
        note: "",
        is_active: true,
        price : 0,
        details:[
          {
            id_item: null,
            name_item: "",
            qty: 1,
            price: 0,
            price_total: 0,
            id_unit: null,
            name_unit: "",
            note: "",
            unitSelect:[]
          },
        ]
      },
      isPageLoading: false
    }
  },
  computed:{
    ...mapState({
      branchList: state => state.companies.lists,
      partnerList: state => state.partners.lists,
      salePriceData: state => state.utils.salePriceArray,
      salePricePage: state => state.utils.salePricePage
    }),
    ...mapGetters({
      salePricePaginateData: 'utils/paginateSalePrice'
    })
  },
  methods:{
    deleteItem(row){
      this.stepOneData.details.splice(row, 1)
    },
    addItem(){
      this.stepOneData.details.push({
        id_item: null,
        name_item: "",
        qty: 1,
        price: 0,
        price_total: 0,
        id_unit: null,
        name_unit: "",
        note: "",
        unitSelect:[]
      })
    },
    unitOptions(i){
      const rows = this.stepOneData.details[i]
      return rows.unitSelect
    },
    modifyRowItem(row){
      let data = this.stepOneData.details[row]
      data.price_total = parseFloat(data.qty)*parseFloat(data.price)
      this.calculateGrandTotal()
    },
    unitChange(value,row){
      const data = this.stepOneData.details[row]
      if (data) {
        this.stepOneData.details[row].name_unit = value.label
        if(value.price){
          this.stepOneData.details[row].price = value.price
          this.modifyRowItem(row)
        }
      }
    },
    async findUnits(row){
      let rows = this.stepOneData.details[row]
      if(!rows.id_item) rows.unitSelect = []
      try {
        const data = await this.$store.dispatch('items/showStepTwo',rows.id_item||'')
        rows.unitSelect = data.map(e => {
          return {
            value: e.id_unit,
            label: e.name_unit,
            price: e.purchase_price
          }
        })
      } catch (error) {
        rows.unitSelect = []
      }
    },
    calculateGrandTotal(){
      let total = 0
      for (let i = 0; i < this.stepOneData.details.length; i++) {
        const e = this.stepOneData.details[i];
        total+=parseFloat(e.price_total)
      }
      this.stepOneData.price = total
    },
    getData(){
      Promise.all([
        this.$store.dispatch('companies/getList'),
        this.$store.dispatch('partners/getList'),
      ])
      .then(() => {
        this.$store.commit('utils/SET_SALE_PRICE_ARRAY',{
          branch: this.branchList,
          partners: this.partnerList
        })
      })
    },
    getDataEdit(){
      const id = this.$route.params.id
      this.isPageLoading = true
      Promise.all([
        this.$store.dispatch('itempackages/show',id),
        this.$store.dispatch('itempackages/showStepTwo',id),
        this.$store.dispatch('companies/getList'),
        this.$store.dispatch('partners/getList'),
      ])
      .then(result => {
        const data = result[0]
        this.stepOneData.code = data.code
        this.stepOneData.name = data.name
        this.stepOneData.alias = data.alias
        this.stepOneData.barcode = data.barcode
        this.stepOneData.min_stock = data.min_stock
        this.stepOneData.note = data.note
        this.stepOneData.id_category = data.id_category
        this.stepOneData.name_category = data.name_category
        this.stepOneData.price = data.price
        this.stepOneData.is_active = data.is_active ? true : false

        if (data.id_category) this.$refs.categorySelect.loadData(this.id_category)
        let detailsdata=[]
        const details = data.item_package_details
        for (let i = 0; i < details.length; i++) {
          const e = details[i];
          let dt = Object.assign({},e)
          if(e.id_unit) dt.unitSelect = [{value:e.id_unit,label:e.name_unit}]
          detailsdata.push(dt)
        }
        this.stepOneData.details = detailsdata
        for (let i = 0; i < detailsdata.length; i++) {
          const e = detailsdata[i];
          this.$nextTick(() => {
            this.$refs[`itemSelect-${i}`][0].initOption([{"value":e.id_item,"label":e.name_item}])
          })
        }
        return result
      })
      .then(result => {
        this.$store.commit('utils/SET_SALE_PRICE_ARRAY',{
          branch: this.branchList,
          partners: this.partnerList,
        })
        const s3data = result[1]
        this.$store.commit('utils/SET_NEW_PRICE_ARRAY',s3data)
        this.isPageLoading = false
      })

    },
    submitStepOne(){
      return new Promise((resolve,reject) => {
        this.$refs.stepOne.validate().then(async success => {
          this.isPageLoading = true
          if (success) {
            try {
              let params = JSON.parse( JSON.stringify(this.stepOneData) )
              params.is_active = params.is_active ? 1 : 0
              let data = {}
              if(!params.id) data = await this.$store.dispatch('itempackages/store',params)
              else data = await this.$store.dispatch('itempackages/update',params)
              if(data.data.id_header) {
                this.stepOneData.id = data.data.id_header
              }
              resolve(true)
            } catch (error) {
              this.handleError(error)
              reject(error)
            }
          } else {
            reject()
          }
          this.isPageLoading = false
        })
      })
    },
    async submitStepTwo(){
      return new Promise((resolve,reject) => {
        this.$refs.stepTwo.validate().then(async success => {
          this.isPageLoading = true
          if (success) {
            const reassign = JSON.parse( JSON.stringify(this.salePriceData) )
            let params = _.flatMapDeep(reassign, (n) => {return n.details})
            for (let i = 0; i < params.length; i++) {
              const e = params[i];
              params[i] = Object.assign(e,{id_item_package:this.stepOneData.id,name_item_package:this.stepOneData.name})
            }
            try {
              await this.$store.dispatch('itempackages/storeStepTwo',{
                data: params,
                id: this.stepOneData.id,
                code: this.stepOneData.name
              })
              this.toastSuccess("OK","Data Berhasil Disimpan")
              this.$router.push({name:'itempackages'})
              resolve(true)
            } catch (error) {
              this.handleError(error)
              reject(error)
            }
          } else {
            reject()
          }
          this.isPageLoading = false
        })
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid ? null : false) : null;
    },

  },
  mounted(){
    if(!this.$route.params.id) this.getData()
    else this.getDataEdit()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
</style>